html,
body,
#root {
    height: 100%;
    margin: 0;
}

.clickable {
    cursor: pointer;
}
